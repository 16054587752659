<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				 <el-form-item label="ID" label-width="30px" prop="order_id">
				   <el-input v-model="queryForm.order_id" placeholder="请输入订单号/用户ID" type="text" clearable style="width: 230px" />
				 </el-form-item>
				<el-form-item label="手机号" label-width="60px" prop="mobile">
				  <el-input v-model="queryForm.mobile" placeholder="请输入手机号" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item label="订单类型" label-width="80px" prop="product_type" >
					<el-select v-model="queryForm.product_type" placeholder="请选择订单类型" size="medium" class="w-100">
						<el-option label="无" value=""></el-option>
						<el-option label="普通订单" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付状态" label-width="80px" prop="pay_status" >
					<el-select v-model="queryForm.pay_status" placeholder="请选择支付状态" size="medium" class="w-100">
						<el-option label="无" value=""></el-option>
						<el-option label="未付款" value="1"></el-option>
						<el-option label="已支付" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单状态" label-width="80px" prop="status" >
					<el-select v-model="queryForm.status" placeholder="请选择支付状态" size="medium" class="w-100">
						<el-option label="无" value=""></el-option>
						<el-option label="正常" value="1"></el-option>
						<el-option label="完成" value="2"></el-option>
						<el-option label="取消" value="3"></el-option>
						<el-option label="待发货" value="4"></el-option>
						<el-option label="已发货" value="5"></el-option>
						<el-option label="已收货" value="6"></el-option>
						<el-option label="已收货" value="7"></el-option>
						<el-option label="待评价" value="8"></el-option>
						<el-option label="已评价" value="9"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间" label-width="70px" prop="time">
				  <el-date-picker
					v-model="queryForm.time"
					type="datetimerange"
					value-format="yyyy-MM-dd HH:mm:ss"
					style="width: 350px;"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				  <el-button type="primary" icon="el-icon-download" size="mini" @click="download">下载</el-button>
				  <el-button type="primary" icon="el-icon-download" size="mini" @click="downloadFhd">下载发货单</el-button>
				  <el-upload
					  class="upload-demo d-inline-block"
					  ref="upload"
					  action="#"
					  :on-change="importExcel"
					  :on-success="importSuccess"
					  :on-error="importError"
					  :before-upload="beforeImportUpload"
					  :limit="1"
					  :multiple="false"
					  :show-file-list="false"
					  accept=".xls, .xlsx"
					  :auto-upload="false"
					  >
				    <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入发货单</el-button>
				 </el-upload>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="订单号" width="135" align="center">
				  <template slot-scope="scope">
					{{ scope.row.order_id }}
				  </template>
				</el-table-column>
				<el-table-column label="用户ID" min-width="130" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.user_id }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="用户昵称" min-width="130" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nickname ? scope.row.nickname : scope.row.username }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="用户手机" min-width="140" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.mobile }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="订单金额" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.amount }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="产品金额" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.goods_amount }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="实付金额" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.payed }}</span>
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="支付积分" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.balance }}</span>
				  </template>
				</el-table-column> -->
<!-- 				<el-table-column label="积分" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.point }}</span>
				  </template>
				</el-table-column> -->
				<el-table-column label="支付状态" min-width="110" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.pay_status === 1" size="mini" type="danger">未支付</el-tag>
					<el-tag v-if="scope.row.pay_status === 2" size="mini" type="success">已支付</el-tag>
					<el-tag v-if="scope.row.pay_status === 3" size="mini" type="warning">已退款</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="下单时间" min-width="175">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="支付方式" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.payment_code }}</span>
				  </template>
				</el-table-column>
	<!-- 			<el-table-column label="订单类型" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.order_type_txt }}</span>
				  </template>
				</el-table-column> -->
				<el-table-column label="团队" min-width="130" align="center" prop="group"></el-table-column>
				<el-table-column label="订单类型" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.product_type_txt }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="订单状态" min-width="80" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="primary">完成</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="danger">取消</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="warning">待发货</el-tag>
					<el-tag v-if="scope.row.status === 5" size="mini" type="success">已发货</el-tag>
					<el-tag v-if="scope.row.status === 6" size="mini" type="success">已收货</el-tag>
					<el-tag v-if="scope.row.status === 7" size="mini" type="info">待评价</el-tag>
					<el-tag v-if="scope.row.status === 8" size="mini" type="success">已评价</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="支付时间" min-width="175">
				  <template slot-scope="scope">
					<i class="el-icon-time" v-if="scope.row.payment_time"/>
					<span>{{ scope.row.payment_time }}</span>
				  </template>
				</el-table-column>

				<el-table-column label="操作" min-width="130" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="success" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="发货" placement="top" v-if="scope.row.status == 4 && scope.row.pay_status == 2 && scope.row.ship_status != 3">
					  <el-button type="primary" icon="el-icon-shopping-bag-2" size="mini" circle @click="handleFh(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="取消" placement="top" v-if="scope.row.status != 3 && scope.row.ship_status == 1">
					  <el-button type="warning" icon="el-icon-refresh-left" size="mini" circle @click="handleCancel(scope.row.order_id)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="scope.row.status == 3 || scope.row.pay_status == 1 || scope.row.pay_status == 3">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.order_id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 详情 -->
		<el-dialog :title="title" :visible.sync="openView" width="70%" :append-to-body="true">
			<el-tabs>
				<el-tab-pane label="基础信息">
					<el-divider content-position="left">订单信息</el-divider>
					<el-row>
						<el-col :span="8">
							订单号：{{orderInfo.order_id || ''}}
						</el-col>
						<el-col :span="8">
							订单总金额：￥{{orderInfo.amount || ''}}元
						</el-col>
						<el-col :span="8">
							支付状态：{{orderInfo.pay_status_txt || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="8">
							发货状态：{{orderInfo.ship_status_txt || ''}}
						</el-col>
						<el-col :span="8">
							订单状态：{{orderInfo.status_txt || ''}}
						</el-col>
						<el-col :span="8">
							已支付金额：{{orderInfo.payed || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="8" v-if="orderInfo.store_name">
							门店名称：{{orderInfo.store_name || ''}}
						</el-col>
		<!-- 				<el-col :span="8">
							购物积分：{{orderInfo.point || '0.00'}}
						</el-col>
						<el-col :span="8">
							绿色积分：{{orderInfo.balance || '0.00'}}
						</el-col> -->
					</el-row>
					<el-row class="mt-2">
						<el-col :span="8">
							订单类型：{{orderInfo.order_type_txt || ''}}
						</el-col>
						<el-col :span="8">
							发货方式：{{orderInfo.fh_type_txt || ''}}
						</el-col>
						<el-col :span="8">
							补货发货状态：{{orderInfo.fh_status_txt || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="8">
							下单来源：{{orderInfo.source_txt || ''}}
						</el-col>
						<el-col :span="8">
							下单时间：{{orderInfo.ctime || ''}}
						</el-col>
						<el-col :span="8" v-if="orderInfo.cancel_reason">
							取消原因：{{orderInfo.cancel_reason || ''}}
						</el-col>
					</el-row>
					<el-divider content-position="left">收货人信息</el-divider>
					<el-row class="mt-2">
						<el-col :span="8">
							收货时间：{{orderInfo.ship_status_txt || ''}}
						</el-col>
						<el-col :span="8">
							收货人姓名：{{orderInfo.ship_name || ''}}
						</el-col>
						<el-col :span="8">
							收货人电话：{{orderInfo.ship_mobile || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="12" v-if="orderInfo.store_name">
							收货门店：{{orderInfo.store_name || ''}}
						</el-col>
						<el-col :span="12">
							收货地址：{{orderInfo.ship_area + orderInfo.ship_address || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="12">
							<el-button type="primary" size="mini" @click="editAddress">修改地址</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left" v-if="orderInfo.delivery">快递信息</el-divider>
					<el-row v-if="orderInfo.delivery">
						<el-col :span="8">
							快递编码：{{orderInfo.delivery.logi_code || ''}}
						</el-col>
						<el-col :span="8">
							快递名称：{{orderInfo.logistics_name || ''}}
						</el-col>
						<el-col :span="8">
							快递编号：{{orderInfo.delivery.logi_no || ''}}
						</el-col>
					</el-row>
					<el-row v-if="orderInfo.delivery" class="mt-2">
						<el-col :span="8">
							发货备注：{{orderInfo.delivery.memo || ''}}
						</el-col>
					</el-row>
					<el-divider content-position="left">买家备注</el-divider>
					<el-row>
						<el-col :span="24">
							买家备注：{{orderInfo.memo || ''}}
						</el-col>
					</el-row>
					<el-row class="mt-2">
						<el-col :span="12">
							<el-button type="primary" size="mini" @click="editMemo">修改备注</el-button>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="商品信息">
					<el-divider content-position="left">商品信息</el-divider>
					  <template>
						<el-table
						  :data="orderInfo.items"
						  style="width: 100%">
						  <el-table-column
							prop="name"
							align="center"
							label="商品名称">
						  </el-table-column>
						  <el-table-column
							prop="cate_name"
							align="center"
							label="商品分类">
						  </el-table-column>
						  <el-table-column
							prop="price"
							align="center"
							label="商品单价">
						  </el-table-column>
						  <el-table-column
							prop="nums"
							align="center"
							label="购买数量">
						  </el-table-column>
						  <el-table-column
							prop="amount"
							align="center"
							label="商品总价">
						  </el-table-column>
						  <el-table-column
							prop="bn"
							align="center"
							label="货品编码">
						  </el-table-column>
						  <el-table-column
							prop="sn"
							align="center"
							label="商品编码">
						  </el-table-column>
						  <el-table-column
							prop="sendnums"
							align="center"
							label="发货数量">
						  </el-table-column>
						</el-table>
					  </template>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="cancelView">取 消</el-button>
			  <el-button type="primary" size="mini" @click="cancelView">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 发货弹框 -->
		<el-dialog title="订单发货" :visible.sync="sendModel" width="70%" :append-to-body="true">
			<el-form ref="form" :model="ship" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="订单号">
							{{ship.order_id || ''}}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="mt-2">
					<el-col :span="12">
						<el-form-item label="收货人姓名" prop="ship_name">
							<el-input v-model="ship.ship_name" placeholder="请输入收货人姓名" size="medium"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="收货人电话" prop="ship_name">
							<el-input v-model="ship.ship_mobile" placeholder="请输入收货人电话" size="medium"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="mt-2">
					<el-col :span="24">
						<el-form-item label="收货地址" prop="ship_address">
							<el-input v-model="ship.ship_address" placeholder="请输入收货人电话" size="medium"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="mt-2">
					<el-col :span="24">
						<el-form-item label="买家备注" prop="memo">
							{{ship.memo || ''}}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="mt-2">
					<el-table
					  :data="ship.items"
					  style="width: 100%">
					  <el-table-column
						prop="name"
						align="center"
						label="商品名称">
					  </el-table-column>
					  <el-table-column
						prop="bn"
						align="center"
						label="货品编码">
					  </el-table-column>
					  <el-table-column
						prop="sn"
						align="center"
						label="商品编码">
					  </el-table-column>
					  <el-table-column
						prop="price"
						align="center"
						label="商品单价">
					  </el-table-column>
					  <el-table-column
						prop="nums"
						align="center"
						label="购买数量">
					  </el-table-column>
					  <el-table-column
						prop="amount"
						align="center"
						label="商品总价">
					  </el-table-column>
					  <el-table-column
						prop="sendnums"
						align="center"
						label="发货数量">
						<template slot-scope="scope">
							<el-input v-model="ship.items[scope.$index].sendnums" size="medium"></el-input>
						</template>
					  </el-table-column>
					</el-table>
				</el-row>
				<el-row class="mt-2">
					<el-col :span="12">
						<el-form-item label="物流公司" prop="logistics_id">
							<el-select v-model="ship.logistics_id" placeholder="请选择物流公司" size="medium" @change="logiChange">
								<el-option label="无" value="0"></el-option>
								<el-option :label="item.logi_name" :value="item.id" v-for="(item,index) in logistics" :key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="物流单号" prop="logi_no">
							<el-input v-model="ship.logi_no" placeholder="请输入收货人电话" size="medium"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="mt-2">
					<el-col :span="24">
						<el-form-item label="发货备注" prop="mark">
							<el-input v-model="ship.mark" placeholder="请输入发货备注" size="medium"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
					<el-button @click="sendModel = false">取 消</el-button>
					<el-button type="primary" @click="sendSubmit">确 定</el-button>
				</div>
		</el-dialog>
        <!-- 修改地址-->
        <el-dialog :visible.sync="openAddress" width="600px" :close-on-click-modal="false" :append-to-body="true" :title="title">
          <el-form ref="address" :model="address" :rules="rules" label-width="100px" size="small">
        	<el-form-item label="收货人姓名" prop="ship_name">
        		<el-input v-model="address.ship_name" placeholder="请填写收货人姓名" type="text"/>
        	</el-form-item>
        	<el-form-item label="收货人电话" prop="ship_mobile">
        		<el-input v-model="address.ship_mobile" placeholder="请填写收货人电话" type="number" clearable/>
        	</el-form-item>
        	<el-form-item label="选择区县" prop="ship_address">
        	  <el-cascader
        	  size="large"
        	  :options="options"
        	  v-model="selectedOptions"
        	  @change="cascaderChange">
        	  </el-cascader>
        	</el-form-item>
        	<el-form-item label="详细地址" prop="ship_address">
        	  <el-input v-model="address.ship_address" placeholder="请填写详细地址" type="text" clearable/>
        	</el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="openAddress = false">取 消</el-button>
            <el-button type="primary" size="mini" @click="addressSubmit">确 定</el-button>
          </div>
        </el-dialog>
		<!-- 修改买家备注-->
		<el-dialog :visible.sync="openMemo" width="600px" :close-on-click-modal="false" :append-to-body="true" :title="title">
		  <el-form ref="memo" :model="memo" label-width="100px" size="small">
			<el-form-item label="买家备注" prop="content">
				<el-input v-model="memo.content" placeholder="请填写买家备注" type="textarea" :rows="3"/>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="openMemo = false">取 消</el-button>
		    <el-button type="primary" size="mini" @click="memoSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js";
	import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'order',
				queryForm: {
					page: 1,
					limit: 10,
					mobile: '',
					order_id:'',
					pay_status:'',
					status:'',
					time:[],
				},
				openView:false,
				orderInfo:{},
				sendModel:false,
				logistics:[],
				ship:{},
				address:{
					order_id:'',
					ship_area_id:'',
					ship_address:'',
					ship_name:'',
					ship_mobile:'',
				},
				rules:{
					ship_name: [
					  { required: true, message: '请填写收货人名称', trigger: 'blur' }
					],
					ship_mobile: [
					  { required: true, message: '请填写收货人电话', trigger: 'blur' }
					],
					ship_address: [
					  { required: true, message: '请填写收货详细地址', trigger: ['blur','change'] }
					],
					ship_area_id: [
					  { required: true, message: '请选择区县', trigger: 'blur' }
					],
				},
				openAddress:false,
				options: regionData,
				selectedOptions: [],
				province:'',
				city:'',
				country:'',
				openMemo:false,
				memo:{
					order_id:'',
					content:'',
				},
			}
		},
		mounted() {
			this.getLogistics()
		},
		methods:{
			editMemo(){
				this.title = '修改备注'
				this.memo.order_id = this.orderInfo.order_id
				this.memo.content = this.orderInfo.memo
				this.openMemo = true
			},
			memoSubmit(){
				this.axios.post('/manage/order/editMemo',this.memo).then(res=>{
				   if(res.status){
					   this.$message.success('修改成功')
					   this.getList()
					   this.openMemo = false
					   this.openView = false
				   }else{
					   this.$message.error(res.msg)
				   }
				})
			},
			cascaderChange(){
				this.province= CodeToText[this.selectedOptions[0]]
				this.city= CodeToText[this.selectedOptions[1]]
				this.country= CodeToText[this.selectedOptions[2]]
				this.address.ship_area_id= this.selectedOptions[2]
			},
			editAddress(){
				this.title = '修改地址'
				this.province = ''
				this.city = ''
				this.country = ''
				this.selectedOptions = []
				this.address.order_id = this.orderInfo.order_id
				this.address.ship_name = this.orderInfo.ship_name
				this.address.ship_mobile = this.orderInfo.ship_mobile
				this.address.ship_address = this.orderInfo.ship_address
				this.address.ship_area_id = this.orderInfo.ship_area_id
				if(this.orderInfo.area_ids){
					this.province = this.orderInfo.province
					this.city = this.orderInfo.city
					this.country = this.orderInfo.country
					this.selectedOptions.push(
						TextToCode[this.province] ? TextToCode[this.province].code : '',
						TextToCode[this.province][this.city] ? TextToCode[this.province][this.city].code : '',
						TextToCode[this.province][this.city][this.country] ? TextToCode[this.province][this.city][this.country].code : ''
					);
				}
				this.openAddress = true
			},
			addressSubmit(){
				this.axios.post('/manage/order/editAddress',this.address).then(res=>{
				   if(res.status){
					   this.$message.success('修改成功')
					   this.getList()
					   this.openAddress = false
					   this.openView = false
				   }else{
					   this.$message.error(res.msg)
				   }
				})
			},
			download(){
				this.axios.get('/manage/order/download',{
					params:this.queryForm,
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '订单列表.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			getLogistics(){
				this.axios.get('/manage/logistics/all').then(res=>{
					if(res.status){
						this.logistics = res.data
					}
				})
			},
			handleView(row){
				this.title = "订单详情"
				this.orderInfo = row
				this.openView = true
			},
			cancelView(){
				this.title = ""
				this.orderInfo = {}
				this.openView = false
			},
			handleFh(row){
				this.ship = {
					order_id:row.order_id,
					ship_name:row.ship_name,
					ship_mobile:row.ship_mobile,
					ship_address:row.ship_area+row.ship_address,
					memo:row.memo,
					items:row.items,
					logistics_id:row.logistics_id,
					logistics_name:row.logistics_name,
					logi_no:row.logi_no,
					mark:row.mark,
					ship_area_id:row.ship_area_id,
				}
				this.sendModel = true
			},
			logiChange(e){
				if(this.logistics.length > 0){
					let item = this.logistics.find(item => e === item.id)
					if(item){
						this.ship.logistics_code = item.logi_code
						this.ship.logistics_name = item.logi_name
						this.ship.logi_code = item.logi_code
					}
				}
			},
			sendSubmit(){
				let item =  this.ship.items.find(item=>item.sendnums <= 0)
				if(item){
					this.$message.error('发货数量不合法')
					return false
				}
				this.axios.post('/manage/order/ship',this.ship).then(res=>{
				   if(res.status){
					   this.$message.success('发货成功')
					   this.getList()
					   this.sendModel = false
				   }else{
					   this.$message.error(res.msg)
				   }
				})
			},
			downloadFhd(){
				this.axios.get('/manage/order/downloadFhd',{
					params:this.queryForm,
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '发货单列表.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/order/importExport',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
			handleCancel(order_id){
				this.$confirm('是否要取消该订单？', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					this.axios.post('/manage/order/cancel',{order_id}).then(res=>{
					   if(res.status){
						   this.$message.success('取消成功')
						   this.getList()
					   }else{
						   this.$message.error(res.msg)
					   }
					})
				})
			},
		}
	}
</script>

<style>
</style>